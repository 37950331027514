import React from 'react';
import styled from '@emotion/styled';

export type TextFieldProps = {
  label: string;
  required?: boolean;
  type: string;
  value: string;
  name: string;
  onChange?: (event: { target: HTMLInputElement }) => void;
  hasError?: boolean;
};

const Input = styled.input<{ hasError: boolean }>`
  outline: none;
  border: none;
  ${props =>
    props.hasError ? `border-bottom: 2px solid ${props.theme.color.text.error}` : `border-bottom: 1px solid black`};
  padding: 30px 0px;
  min-width: 100%;
  caret-color: ${({ theme }) => theme.color.primary};
  :focus,
  :active {
    background-color: ${({ theme }) => theme.color.palette['pink-lighten-6']};
    border-bottom: 2px solid ${({ theme }) => theme.color.primary};
    padding-left: 10px;
    transition: border-bottom, padding-left 0.2s ease;
  }
`;

export const TextField: React.FC<TextFieldProps> = props => {
  const placeholder = props.label + (props.required ? ' *' : '');

  return (
    <>
      <Input
        hasError={props.hasError ?? false}
        type={props.type}
        required={props.required}
        placeholder={placeholder}
        value={props.value}
        onChange={props.onChange}
        name={props.name}
        id={props.name}
      />
    </>
  );
};
