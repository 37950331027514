import React, { SetStateAction } from 'react';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import { AvailableLocales } from '../../../locale';
import { FormValues, FormErrors, FieldConfigOptions } from './types';
import { SelectChoice, APIValue } from '../../atoms/select';

type HandleCheckProps = {
  setIsConsentGiven: (value: React.SetStateAction<boolean>) => void;
};
/** Checking consent box */
export const handleCheck = (event: React.ChangeEvent<HTMLInputElement>, props: HandleCheckProps): void => {
  props.setIsConsentGiven(event.target.checked);
};

export type HandleConsentSubmitProps = {
  setStep: (value: React.SetStateAction<number>) => void;
};
/** Submitting consent page */
export const handleConsentSubmit = (step: number, props: HandleConsentSubmitProps): void => {
  props.setStep(step + 1);
};

/** Clearing user inputs */
type ClearAllUserInputsProps = {
  initialValues: FormValues;
  setFormValues: (value: React.SetStateAction<FormValues>) => void;
};
const clearAllUserInputs = (props: ClearAllUserInputsProps): void => {
  props.setFormValues((previousState: FormValues) => {
    return {
      ...previousState,
      ...props.initialValues,
    };
  });
};

/** Clearing errors */
type ClearAllErrorsProps = {
  initialErrors: FormErrors;
  setFormErrors: (value: React.SetStateAction<FormErrors>) => void;
};

const clearAllErrors = (props: ClearAllErrorsProps): void => {
  props.setFormErrors((previousState: FormErrors) => {
    return {
      ...previousState,
      ...props.initialErrors,
    };
  });
};

type HandleSelectChangeProps = {
  setFormErrors: (value: React.SetStateAction<FormErrors>) => void;
  setFormValues: (value: React.SetStateAction<FormValues>) => void;
};

export const handleSelectChange = (
  choice: SelectChoice<APIValue>,
  name: string,
  props: HandleSelectChangeProps
): void => {
  props.setFormErrors((previousState: FormErrors) => {
    return {
      ...previousState,
      [name]: '',
    };
  });
  props.setFormValues((previousState: FormValues) => {
    return {
      ...previousState,
      [name]: choice.field,
      countryISOCode: choice.isoCode ?? previousState.countryISOCode,
      categoryCode: choice.category ?? previousState.categoryCode,
    };
  });
};

type HandleAddAttachmentProps = {
  setFormErrors: (value: React.SetStateAction<FormErrors>) => void;
  setFileUpload: (value: React.SetStateAction<File | undefined>) => void;
};

export const handleAddAttachment = (
  event: React.ChangeEvent<HTMLInputElement>,
  props: HandleAddAttachmentProps
): void => {
  props.setFormErrors((previousState: FormErrors) => {
    return {
      ...previousState,
      file: '',
    };
  });
  const { files } = event.target;
  if (!files) {
    return;
  }
  props.setFileUpload(files[0]);
};

type HandleRemoveAttachmentProps = {
  setFormErrors: (value: React.SetStateAction<FormErrors>) => void;
  setFileUpload: (value: React.SetStateAction<File | undefined>) => void;
};

export const handleRemoveAttachment = (props: HandleRemoveAttachmentProps): void => {
  props.setFormErrors((previousState: FormErrors) => {
    return {
      ...previousState,
      file: '',
    };
  });
  props.setFileUpload(undefined);
};

type HandleRecaptchaLoadedProps = {
  setRecaptchaLoading: (value: React.SetStateAction<boolean>) => void;
};

export const handleRecaptchaLoaded = (props: HandleRecaptchaLoadedProps): void => {
  props.setRecaptchaLoading(false);
};

type HandleCompleteRecaptchaProps = {
  setFormErrors: (value: React.SetStateAction<FormErrors>) => void;
};

export const handleCompleteRecaptcha = (props: HandleCompleteRecaptchaProps): void => {
  props.setFormErrors((previousState: FormErrors) => {
    return {
      ...previousState,
      recaptcha: '',
    };
  });
};

type HandleInputChangeProps = {
  setFormErrors: (value: React.SetStateAction<FormErrors>) => void;
  setFormValues: (value: React.SetStateAction<FormValues>) => void;
};

export const handleInputChange = (
  event: { target: HTMLInputElement | HTMLTextAreaElement },
  props: HandleInputChangeProps
): void => {
  props.setFormErrors((previousState: FormErrors) => {
    return {
      ...previousState,
      [name]: '',
    };
  });
  const { name, value } = event.target;
  props.setFormValues((previousState: FormValues) => {
    return {
      ...previousState,
      [name]: value,
    };
  });
};

/** Validating form inputs */
export type ValidateFormProps = {
  initialErrors: FormErrors;
  setFormErrors: (value: React.SetStateAction<FormErrors>) => void;
  formValues: FormValues;
  setFormValues: (value: React.SetStateAction<FormValues>) => void;
  fieldConfigOptions: FieldConfigOptions;
  captchaRef: React.RefObject<ReCAPTCHA>;
  fileUpload?: File;
  submitValidationError: string;
};

const validateForm = ({
  initialErrors,
  setFormErrors,
  formValues,
  setFormValues,
  fieldConfigOptions,
  captchaRef,
  fileUpload,
  submitValidationError,
}: ValidateFormProps): boolean => {
  clearAllErrors({ initialErrors, setFormErrors });
  let isValidationPassed = true;

  // Email format validation
  // eslint-disable-next-line no-useless-escape
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (formValues.email && emailRegex.test(formValues.email) === false) {
    setFormErrors((previousState: FormErrors) => {
      return {
        ...previousState,
        email: fieldConfigOptions.email.errors.invalid,
      };
    });
    isValidationPassed = false;
  }

  // Required fields validation
  for (const field in formValues) {
    const typedField = field as keyof typeof formValues;
    if (fieldConfigOptions[typedField] && fieldConfigOptions[typedField].required && formValues[typedField] === '') {
      const error = fieldConfigOptions[typedField].errors.required;
      setFormErrors((previousState: FormErrors) => {
        return {
          ...previousState,
          [typedField]: error,
        };
      });
      isValidationPassed = false;
    }
  }

  if (!captchaRef?.current?.getValue()) {
    setFormErrors((previousState: FormErrors) => {
      return {
        ...previousState,
        recaptcha: fieldConfigOptions.recaptcha.errors.required,
      };
    });
    isValidationPassed = false;
  }

  const attachmentTypeRegex = /image\/\w+|application\/pdf/gm;
  if (fileUpload && !attachmentTypeRegex.test(fileUpload.type)) {
    setFormErrors((previousState: FormErrors) => {
      return {
        ...previousState,
        file: fieldConfigOptions.file.errors.format,
      };
    });
    isValidationPassed = false;
  }

  const attachmentMaxSize = (process.env.GATSBY_ATTACHMENT_MAX_SIZE as unknown) as number;
  if (fileUpload && fileUpload.size >= attachmentMaxSize) {
    const errorMessage =
      fieldConfigOptions.file.errors.attachmentSizeOne +
      attachmentMaxSize / 1000000 +
      fieldConfigOptions.file.errors.attachmentSizeTwo;
    setFormErrors((previousState: FormErrors) => {
      return {
        ...previousState,
        file: errorMessage,
      };
    });
    isValidationPassed = false;
  }

  if (!isValidationPassed) {
    setFormErrors((previousState: FormErrors) => {
      return {
        ...previousState,
        validation: submitValidationError,
      };
    });
  }
  return isValidationPassed;
};

/** Submitting contact form */
export type HandleSubmitProps = {
  validateFormProps: ValidateFormProps;
  initialValues: FormValues;
  setSubmitting: (value: React.SetStateAction<boolean>) => void;
  locale: AvailableLocales;
  step: number;
  setStep: (value: React.SetStateAction<number>) => void;
  submitServerError: string;
};

export const handleMessageSubmit = async (
  event: React.FormEvent<HTMLFormElement>,
  handleSubmitProps: HandleSubmitProps
): Promise<void> => {
  event.preventDefault();

  const {
    validateFormProps,
    setSubmitting,
    locale,
    initialValues,
    setStep,
    step,
    submitServerError,
  } = handleSubmitProps;

  // perform validation
  const isValidated: boolean = validateForm(validateFormProps);

  if (!isValidated) {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  } else {
    setSubmitting(true);
    const { captchaRef, formValues, fileUpload, setFormValues, initialErrors, setFormErrors } = validateFormProps;
    const recaptchaToken = captchaRef?.current?.getValue();
    const lang = locale.toUpperCase();

    const formData = new FormData();
    formData.append('lang', lang);
    if (recaptchaToken) {
      formData.append('token', recaptchaToken);
    }

    formData.append('email', formValues.email.trim());
    formData.append('lastname', formValues.lastname.trim());
    formData.append('firstname', formValues.firstname.trim());

    // Concatenating subject and company (if any) in comment
    formValues.subject
      ? formValues.company
        ? formData.append(
            'comment',
            `***Subject:*** ${formValues.subject.trim()}\n***Company:*** ${formValues.company.trim()}\n\n${formValues.comment.trim()}`
          )
        : formData.append('comment', `***Subject:*** ${formValues.subject.trim()}\n\n ${formValues.comment.trim()}`)
      : formData.append('comment', formValues.comment.trim());

    formData.append('category', formValues.categoryCode as string);
    formData.append('country', formValues.countryISOCode as string);

    if (fileUpload) {
      formData.append('file', fileUpload);
    }

    const postUrl = process.env.GATSBY_API_URL
      ? `${process.env.GATSBY_API_URL}/api/contact-forms`
      : `/api/contact-forms`;

    await axios
      .post(postUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(res => {
        clearAllUserInputs({ initialValues, setFormValues });
        clearAllErrors({ initialErrors, setFormErrors });
        captchaRef?.current?.reset();
        setStep(step + 1);
      })
      .catch(error => {
        // in case of a 50x or 40x error, we display a generic error
        setFormErrors(previousState => {
          return {
            ...previousState,
            submit: [...previousState.submit, submitServerError],
          };
        });

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      })
      .finally(() => {
        setSubmitting(false);
      });
  }
};
