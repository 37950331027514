import React from 'react';
import styled from '@emotion/styled';

export type TextareaFieldProps = {
  placeholder: string;
  name: string;
  onChange?: (event: { target: HTMLTextAreaElement }) => void;
  value: string;
  required?: boolean;
  hasError?: boolean;
};

const Textarea = styled.textarea<{ hasError: boolean }>`
  min-width: 100%;
  outline: none;
  resize: none;
  padding: 20px;
  margin-bottom: 20px;
  line-height: 150%;
  caret-color: ${({ theme }) => theme.color.primary};
  ${props => (props.hasError ? `border: 2px solid ${props.theme.color.text.error}` : `border: 1px solid black`)};
  :focus,
  :active {
    border: 2px solid ${({ theme }) => theme.color.primary};
    transition: border 0.2s ease-out;
  }
`;

export const TextareaField: React.FC<TextareaFieldProps> = props => {
  return (
    <Textarea
      hasError={props.hasError ?? false}
      rows={6}
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.onChange}
      name={props.name}
      required={props.required}
    />
  );
};
