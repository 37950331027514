import React, { useState } from 'react';

import {
  handleCheck,
  handleConsentSubmit,
  handleInputChange,
  handleSelectChange,
  handleRecaptchaLoaded,
  handleCompleteRecaptcha,
  handleAddAttachment,
  handleRemoveAttachment,
} from './hook';
import { ContactFormStepOne, ContactFormStepOneProps } from './step-1';
import { ContactFormStepTwo, ContactFormStepTwoProps } from './step-2';
import { ContactFormStepThree, ContactFormStepThreeProps } from './step-3';
import { FormValues, FormErrors } from './types';

import { CtaProps } from '../../molecules/cta';
import { AlignedMarkdownProps } from '../../atoms/aligned-markdown';

import { ContactFormWrapper, Header } from './styled-components';

export type ContactFormProps = {
  title: string;
  description?: AlignedMarkdownProps;
  legalNotice?: AlignedMarkdownProps;
  openFormCTA?: CtaProps;
  successTitle?: string;
  successText?: AlignedMarkdownProps;
};

export const ContactForm: React.FC<ContactFormProps> = props => {
  const initialValues: FormValues = {
    subject: '',
    lastname: '',
    firstname: '',
    email: '',
    country: '',
    countryISOCode: '',
    company: '',
    category: '',
    categoryCode: '',
    comment: '',
  };

  const initialErrors: FormErrors = {
    validation: '',
    subject: '',
    lastname: '',
    firstname: '',
    email: '',
    country: '',
    company: '',
    category: '',
    comment: '',
    file: '',
    recaptcha: '',
    submit: [],
  };

  const [formValues, setFormValues] = useState<FormValues>(initialValues);
  const [fileUpload, setFileUpload] = useState<File>();
  const [formErrors, setFormErrors] = useState<FormErrors>(initialErrors);
  const [step, setStep] = useState<number>(1);
  const [isConsentGiven, setIsConsentGiven] = useState<boolean>(false);
  const [isRecaptchaLoading, setRecaptchaLoading] = useState<boolean>(true);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const contactFormStepOneProps: ContactFormStepOneProps = {
    description: props.description,
    legalNotice: props.legalNotice,
    isConsentGiven: isConsentGiven,
    handleCheck: (event: React.ChangeEvent<HTMLInputElement>) => handleCheck(event, { setIsConsentGiven }),
    handleConsentSubmit: () => handleConsentSubmit(step, { setStep }),
    CTA: props.openFormCTA,
  };

  const contactFormStepTwoProps: ContactFormStepTwoProps = {
    initialErrors,
    setFormErrors,
    formErrors,
    initialValues,
    setFormValues,
    formValues,
    fileUpload,
    handleInputChange: event => handleInputChange(event, { setFormErrors, setFormValues }),
    handleSelectChange: (event, name) => handleSelectChange(event, name, { setFormErrors, setFormValues }),
    isRecaptchaLoading,
    handleRecaptchaLoaded: () => handleRecaptchaLoaded({ setRecaptchaLoading }),
    handleCompleteRecaptcha: () => handleCompleteRecaptcha({ setFormErrors }),
    isSubmitting,
    setSubmitting,
    handleAddAttachment: event => handleAddAttachment(event, { setFormErrors, setFileUpload }),
    handleRemoveAttachment: () => handleRemoveAttachment({ setFormErrors, setFileUpload }),
    step,
    setStep,
  };

  const contactFormStepThreeProps: ContactFormStepThreeProps = {
    successTitle: props.successTitle,
    successText: props.successText,
  };

  return (
    <ContactFormWrapper>
      {(step === 1 || step === 2) && <Header>{props.title}</Header>}
      {step === 1 && <ContactFormStepOne {...contactFormStepOneProps} />}
      {step === 2 && <ContactFormStepTwo {...contactFormStepTwoProps} />}
      {step === 3 && <ContactFormStepThree {...contactFormStepThreeProps} />}
    </ContactFormWrapper>
  );
};
