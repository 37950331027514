import React, { useRef } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import { parseLocale } from '../../../locale';
import ReCAPTCHA from 'react-google-recaptcha';

import { FieldConfigOptions, FormErrors, FormValues } from './types';

import { handleMessageSubmit, HandleSubmitProps, ValidateFormProps } from './hook';

import { Loader } from '../../atoms/loader';
import { TextField } from '../../atoms/text-field';
import { TextareaField } from '../../atoms/textarea-field';
import { Select, SelectChoice, APIValue } from '../../atoms/select';
import { Setpoint } from '../../atoms/setpoint';
import { CTA } from '../../molecules/cta';
import { UploadFileButton } from '../../organisms/upload-file-button';
import { generateOrderedLocCountryList } from '../../../utils/countries/countries-utils';

import {
  Form,
  TextAreaWrapper,
  ButtonWrapper,
  AcceptedFormatsWrapper,
  LoaderWrapper,
  GlobalErrorWrapper,
  InputErrorWrapper,
  TextAreaErrorWrapper,
  RecaptchaErrorWrapper,
  FileErrorWrapper,
  SubmitErrorsWrapper,
  SubmitErrorsList,
} from './styled-components';

export type ContactFormStepTwoProps = {
  initialErrors: FormErrors;
  formErrors: FormErrors;
  setFormErrors: React.Dispatch<React.SetStateAction<FormErrors>>;
  initialValues: FormValues;
  formValues: FormValues;
  setFormValues: React.Dispatch<React.SetStateAction<FormValues>>;
  fileUpload: File | undefined;
  handleInputChange: (event: { target: HTMLInputElement | HTMLTextAreaElement }) => void;
  handleSelectChange: (choice: SelectChoice<APIValue>, name: string) => void;
  isRecaptchaLoading: boolean;
  handleRecaptchaLoaded: () => void;
  handleCompleteRecaptcha: () => void;
  setSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
  isSubmitting: boolean;
  handleAddAttachment: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleRemoveAttachment: () => void;
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
};

export const ContactFormStepTwo: React.FC<ContactFormStepTwoProps> = props => {
  const intl = useIntl();
  const locale = parseLocale(intl.locale);

  const attachmentMaxSize = (process.env.GATSBY_ATTACHMENT_MAX_SIZE as unknown) as number;

  const translations = {
    subjectLabel: intl.formatMessage({ id: 'contact.subject' }),
    lastnameLabel: intl.formatMessage({ id: 'contact.lastname' }),
    firstnameLabel: intl.formatMessage({ id: 'contact.firstname' }),
    emailLabel: intl.formatMessage({ id: 'contact.email' }),
    countryLabel: intl.formatMessage({ id: 'contact.country' }),
    categoryLabel: intl.formatMessage({ id: 'contact.category' }),
    companyLabel: intl.formatMessage({ id: 'contact.company' }),
    commentPlaceholder: intl.formatMessage({ id: 'contact.comment' }),
    uploadLabel: intl.formatMessage({ id: 'contact.upload' }),
    uploadFormats:
      intl.formatMessage({ id: 'contact.upload.formats.one' }) +
      attachmentMaxSize / 1000000 +
      intl.formatMessage({ id: 'contact.upload.formats.two' }),
    mandatoryLabel: intl.formatMessage({ id: 'contact.mandatory' }),
    sendLabel: intl.formatMessage({ id: 'contact.send' }),
    submitValidationError: intl.formatMessage({ id: 'contact.error.validation' }),
    submitServerError: intl.formatMessage({ id: 'contact.error.server' }),
  };

  const fieldConfigOptions: FieldConfigOptions = {
    subject: {
      required: true,
      errors: {
        required: intl.formatMessage({ id: 'contact.error.subject' }),
      },
    },
    firstname: {
      required: true,
      errors: {
        required: intl.formatMessage({ id: 'contact.error.firstname' }),
      },
    },
    lastname: {
      required: true,
      errors: {
        required: intl.formatMessage({ id: 'contact.error.lastname' }),
      },
    },
    email: {
      required: true,
      errors: {
        required: intl.formatMessage({ id: 'contact.error.email.missing' }),
        invalid: intl.formatMessage({ id: 'contact.error.email.invalid' }),
      },
    },
    country: {
      required: true,
      errors: {
        required: intl.formatMessage({ id: 'contact.error.country' }),
      },
    },
    category: {
      required: true,
      errors: {
        required: intl.formatMessage({ id: 'contact.error.category' }),
      },
    },
    comment: {
      required: true,
      errors: {
        required: intl.formatMessage({ id: 'contact.error.comment' }),
      },
    },
    file: {
      required: false,
      errors: {
        format: intl.formatMessage({ id: 'contact.error.attachment.format' }),
        attachmentSizeOne: intl.formatMessage({ id: 'contact.error.attachment.size.one' }),
        attachmentSizeTwo: intl.formatMessage({ id: 'contact.error.attachment.size.two' }),
      },
    },
    recaptcha: {
      required: true,
      errors: {
        required: intl.formatMessage({ id: 'contact.error.recaptcha' }),
      },
    },
  };

  const siteKey = process.env.GATSBY_CAPTCHA_SITE_KEY as string;
  const captchaRef = useRef<ReCAPTCHA>(null);

  const categoryChoices: SelectChoice<APIValue>[] = [
    { id: 1, field: 'inquiry', unavailable: false, category: 'INFORMATION_REQUEST' },
    { id: 2, field: 'partnership', unavailable: false, category: 'PARTNERSHIP' },
    { id: 3, field: 'press', unavailable: false, category: 'PRESS_INQUIRY' },
    // { id: 4, field: 'invitation', unavailable: false, category: 'INVITATION' },
    { id: 5, field: 'other', unavailable: false, category: 'OTHER' },
  ];

  const localizeSelectChoices = (
    selectChoices: SelectChoice<APIValue>[],
    intlKey: string
  ): SelectChoice<APIValue>[] => {
    const intlSelectChoices: SelectChoice<APIValue>[] = [];
    selectChoices.map((choice: SelectChoice<APIValue>) => {
      return intlSelectChoices.push({
        ...choice,
        field: intl.formatMessage({ id: `${intlKey}.${choice.field}` }),
      });
    });
    return intlSelectChoices;
  };

  const localizedCategoryChoices: SelectChoice<APIValue>[] = localizeSelectChoices(
    categoryChoices,
    'contact.category.choices'
  );

  const localizedCountryChoices: SelectChoice<APIValue>[] = generateOrderedLocCountryList(locale);

  const validateFormProps: ValidateFormProps = {
    initialErrors: props.initialErrors,
    setFormErrors: props.setFormErrors,
    formValues: props.formValues,
    setFormValues: props.setFormValues,
    fieldConfigOptions,
    captchaRef,
    fileUpload: props.fileUpload,
    submitValidationError: translations.submitValidationError,
  };
  const handleSubmitProps: HandleSubmitProps = {
    validateFormProps,
    initialValues: props.initialValues,
    setSubmitting: props.setSubmitting,
    locale,
    step: props.step,
    setStep: props.setStep,
    submitServerError: translations.submitServerError,
  };

  return (
    <Form
      onSubmit={event =>
        handleMessageSubmit(event, {
          ...handleSubmitProps,
        })
      }
    >
      {props.formErrors.validation && <GlobalErrorWrapper>{props.formErrors.validation}</GlobalErrorWrapper>}
      {props.formErrors.submit.length > 0 && (
        <SubmitErrorsWrapper>
          <SubmitErrorsList>
            {props.formErrors.submit.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </SubmitErrorsList>
        </SubmitErrorsWrapper>
      )}

      <Select
        selectTitle={translations.categoryLabel}
        selectChoices={localizedCategoryChoices as SelectChoice<APIValue>[]}
        name="category"
        onChange={(event, name) => props.handleSelectChange(event, name)}
        value={props.formValues.category}
        hasError={!!props.formErrors.category}
        scrollBarOn={false}
      />
      {props.formErrors.category && <InputErrorWrapper>{props.formErrors.category}</InputErrorWrapper>}
      <TextField
        label={translations.lastnameLabel}
        type="text"
        value={props.formValues.lastname}
        name="lastname"
        onChange={props.handleInputChange}
        hasError={!!props.formErrors.lastname}
      />
      {props.formErrors.lastname && <InputErrorWrapper>{props.formErrors.lastname} </InputErrorWrapper>}
      <TextField
        label={translations.firstnameLabel}
        type="text"
        value={props.formValues.firstname}
        name="firstname"
        onChange={props.handleInputChange}
        hasError={!!props.formErrors.firstname}
      />
      {props.formErrors.firstname && <InputErrorWrapper>{props.formErrors.firstname}</InputErrorWrapper>}
      <TextField
        label={translations.emailLabel}
        type="text"
        value={props.formValues.email.trim()}
        name="email"
        onChange={props.handleInputChange}
        hasError={!!props.formErrors.email}
      />
      {props.formErrors.email && <InputErrorWrapper>{props.formErrors.email}</InputErrorWrapper>}
      <Select
        selectTitle={translations.countryLabel}
        selectChoices={localizedCountryChoices as SelectChoice<APIValue>[]}
        name="country"
        onChange={(event, name) => props.handleSelectChange(event, name)}
        value={props.formValues.country}
        hasError={!!props.formErrors.country}
        scrollBarOn={true}
      />
      {props.formErrors.country && <InputErrorWrapper>{props.formErrors.country}</InputErrorWrapper>}
      <TextField
        label={translations.companyLabel}
        type="text"
        value={props.formValues.company}
        name="company"
        onChange={props.handleInputChange}
      />
      <TextField
        label={translations.subjectLabel}
        type="text"
        value={props.formValues.subject}
        name="subject"
        onChange={props.handleInputChange}
        hasError={!!props.formErrors.subject}
      />
      {props.formErrors.subject && <InputErrorWrapper>{props.formErrors.subject} </InputErrorWrapper>}

      <TextAreaWrapper>
        <TextareaField
          placeholder={translations.commentPlaceholder}
          name="comment"
          value={props.formValues.comment}
          onChange={props.handleInputChange}
          hasError={!!props.formErrors.comment}
        />
      </TextAreaWrapper>
      {props.formErrors.comment && <TextAreaErrorWrapper>{props.formErrors.comment}</TextAreaErrorWrapper>}
      {props.isRecaptchaLoading && (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      )}
      <ReCAPTCHA
        sitekey={siteKey}
        ref={captchaRef}
        asyncScriptOnLoad={props.handleRecaptchaLoaded}
        onChange={props.handleCompleteRecaptcha}
      />
      {props.formErrors.recaptcha && <RecaptchaErrorWrapper>{props.formErrors.recaptcha}</RecaptchaErrorWrapper>}
      {props.isSubmitting && (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      )}
      {!props.isSubmitting && (
        <ButtonWrapper>
          <AcceptedFormatsWrapper>{translations.uploadFormats}</AcceptedFormatsWrapper>
          <UploadFileButton
            text={translations.uploadLabel}
            title={translations.uploadLabel}
            handleAddAttachment={props.handleAddAttachment}
            handleRemoveAttachment={props.handleRemoveAttachment}
          />
          {props.formErrors.file && <FileErrorWrapper>{props.formErrors.file}</FileErrorWrapper>}
          <CTA text={translations.sendLabel} type="submit" />
        </ButtonWrapper>
      )}
      <Setpoint text={translations.mandatoryLabel} />
    </Form>
  );
};
