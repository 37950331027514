import React from 'react';
import styled from '@emotion/styled';

export type SetpointProps = {
  text: string;
};

const SetpointWrapper = styled.div`
  display: flex;
  margin: 30px 0;
  line-height: normal;
`;

const Text = styled.div`
  padding-left: 10px;
`;

export const Setpoint: React.FC<SetpointProps> = props => {
  return (
    <SetpointWrapper>
      {/* <div>{'\u2022'}</div> */}
      <Text>{props.text}</Text>
    </SetpointWrapper>
  );
};
