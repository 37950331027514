import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';

import { AlignedMarkdown, AlignedMarkdownProps } from '../../atoms/aligned-markdown';
import { Checkbox } from '../../atoms/checkbox';
import { CTA, CtaProps } from '../../../components/molecules/cta';

import { DescriptionWrapper, disabled } from './styled-components';

export type ContactFormStepOneProps = {
  description?: AlignedMarkdownProps;
  legalNotice?: AlignedMarkdownProps;
  isConsentGiven: boolean;
  handleCheck: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleConsentSubmit: () => void;
  CTA?: CtaProps;
};

export const ContactFormStepOne: React.FC<ContactFormStepOneProps> = props => {
  const intl = useIntl();

  const translations = {
    consentLabel: intl.formatMessage({ id: 'contact.consent' }),
  };

  return (
    <>
      <DescriptionWrapper>
        <AlignedMarkdown {...props.description} />
      </DescriptionWrapper>
      <AlignedMarkdown {...props.legalNotice} />
      <Checkbox text={translations.consentLabel} checked={props.isConsentGiven} onChange={props.handleCheck} />
      <CTA
        text={props?.CTA?.text ?? ''}
        onClick={props.handleConsentSubmit}
        css={!props.isConsentGiven ? disabled : undefined}
        link={undefined}
      />
    </>
  );
};
