import React, { useState, Fragment } from 'react';
import { css, ClassNames } from '@emotion/react';

import { Listbox, Transition } from '@headlessui/react';

import { theme } from '../../theme';

export type APIValue = {
  [key: string]: string | number | boolean;
};

export type SelectChoice<T> = T & {
  id: number;
  field: string;
  unavailable: boolean;
};

export type SelectProps = {
  selectTitle: string;
  selectChoices: SelectChoice<APIValue>[];
  name: string;
  onChange: (choice: SelectChoice<APIValue>, name: string) => void;
  value: string;
  hasError?: boolean;
  scrollBarOn?: boolean;
};

const listbox = css({
  minWidth: '100%',
});

const button = css({
  borderBottom: '1px solid black',
  minWidth: '100%',
  padding: '30px 0',
  textAlign: 'start',
  color: `${theme.color.palette['base-gray-3']}`,
});

const errorButton = css({
  borderBottom: `2px solid ${theme.color.text.error}`,
  minWidth: '100%',
  padding: '30px 0',
  textAlign: 'start',
  color: `${theme.color.palette['base-gray-3']}`,
});

const choiceMadeButton = css({
  borderBottom: '1px solid black',
  minWidth: '100%',
  padding: '30px 0',
  textAlign: 'start',
  color: `${theme.color.text.body}`,
});

const openButton = css({
  borderBottom: `2px solid ${theme.color.primary}`,
  minWidth: '100%',
  padding: '30px 15px',
  textAlign: 'start',
  backgroundColor: `${theme.color.palette['pink-lighten-6']}`,
});

const openChoiceMadeButton = css({
  borderBottom: `2px solid ${theme.color.primary}`,
  minWidth: '100%',
  padding: '30px 15px',
  textAlign: 'start',
  backgroundColor: `${theme.color.palette['pink-lighten-6']}`,
  color: `${theme.color.text.body}`,
});

const options = css({
  margin: '0px 0px',
  padding: '10px 0px',
  zIndex: '10',
  minWidth: '100%',
  position: 'absolute',
  backgroundColor: `${theme.color.palette['pink-lighten-6']}`,
  lineHeight: '0.4',
});

const optionsWithScrollbar = css({
  margin: '0px 0px',
  padding: '10px 0px',
  zIndex: '10',
  minWidth: '100%',
  position: 'absolute',
  backgroundColor: `${theme.color.palette['pink-lighten-6']}`,
  lineHeight: '0.4',
  maxHeight: '400px',
  overflowY: 'scroll',
});

const option = css({
  padding: '10px 15px',
  listStyleType: 'none',
  minWidth: '100%',
  '&:hover': {
    color: `${theme.color.primary}`,
    transition: 'color 0.4s ease-in',
  },
  lineHeight: 1,
});

const transition = css({
  width: '100%',
});

const enterStyle = {
  transitionProperty:
    'color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter',
  transitionTimingFunction: 'cubic-bezier(0, 0, 0.2, 1)',
  transitionDuration: '100ms',
};

const enterFromStyle = {
  transform: 'scale(.95)',
  opacity: '0',
};
const enterToStyle = {
  transform: 'scale(1)',
  opacity: '1',
};
const leaveStyle = {
  transitionProperty:
    'color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter',
  transitionTimingFunction: 'cubic- bezier(0, 0, 0.2, 1)',
  transitionDuration: '75ms',
};
const leaveFromStyle = {
  transform: 'scale(1)',
  opacity: '1',
};
const leaveToStyle = {
  transform: 'scale(.95)',
  opacity: '0',
};

interface SelectedChoiceState {
  id: number;
  field: string;
  unavailable: boolean;
}

export const Select: React.FC<SelectProps> = props => {
  const [selectedChoice, setSelectedChoice] = useState<SelectedChoiceState | undefined>({
    id: 1,
    field: props.value,
    unavailable: false,
  });

  const handleSelect = (choice: SelectChoice<APIValue>): void => {
    setSelectedChoice(choice);
    props.onChange(choice, props.name);
  };

  return (
    // @ts-expect-error ***
    <Listbox css={listbox} value={selectedChoice} onChange={handleSelect} as={Fragment} name={props.name}>
      {({ open }) => (
        <>
          {/*
			// @ts-expect-error *** */}
          <Listbox.Button
            css={
              props.hasError
                ? errorButton
                : open
                ? selectedChoice?.field
                  ? openChoiceMadeButton
                  : openButton
                : selectedChoice?.field
                ? choiceMadeButton
                : button
            }
          >
            {selectedChoice?.field ? selectedChoice.field : props.selectTitle}
          </Listbox.Button>

          <ClassNames>
            {({ css }) => (
              // @ts-expect-error ***
              <Transition
                // show={open}
                enter={css(enterStyle)}
                enterFrom={css(enterFromStyle)}
                enterTo={css(enterToStyle)}
                leave={css(leaveStyle)}
                leaveFrom={css(leaveFromStyle)}
                leaveTo={css(leaveToStyle)}
                css={transition}
              >
                {/*
        			// @ts-expect-error *** */}
                <Listbox.Options static css={props.scrollBarOn ? optionsWithScrollbar : options}>
                  {props.selectChoices.map(choice => (
                    // @ts-expect-error ***
                    <Listbox.Option key={choice.id} value={choice} disabled={choice.unavailable} css={option}>
                      {choice.field}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            )}
          </ClassNames>
        </>
      )}
    </Listbox>
  );
};
