import englishCountries from './en/world.json';
import frenchCountries from './fr/world.json';
import { AvailableLocales } from '../../locale';
import { SelectChoice, APIValue } from '../../components/atoms/select';

// 1) Adding required properties for building the select component and ordering the elements

interface InputCountry {
  id: number;
  alpha2: string;
  alpha3: string;
  name: string;
}

// Countries to exclude from the picklist
const excludedCountries = [
  'ax', // Åland Islands
  'as', // American Samoa
  'ai', // Anguilla
  'aq', // Antartica
  'bv', // Bouvet Island
  'io', // British Indian Ocean Territory
  'cx', // Christmas Island
  'cc', // Cocos (Keeling) Islands
  'kp', // Korea (Democratic People's Republic of)
  'fo', // Faroe Islands
  'gf', // FRENCH GUIANA
  'pf', // FRENCH POLYNESIA
  'tf', // FRENCH SOUTHERN TERRITORIES
  'gi', // Gibraltar
  'gp', // Guadeloupe
  'gg', // Guernsey
  'hm', // Heard Island and McDonald Islands
  'im', // Isle of Man
  'mq', // Martinique
  'yt', // Mayotte
  'ms', // Montserrat
  'nc', // New Caledonia
  'nf', // Norfolk Island
  'mp', // Northern Mariana Islands
  'pn', // Pitcairn
  're', // Réunion
  'bl', // Saint Barthélemy
  'mf', // Saint Martin (French part)
  'sx', // Sint Maarten (Dutch part)
  'sh', // Saint Helena, Ascension and Tristan da Cunha
  'pm', // Saint Pierre and Miquelon
  'sj', // Svalbard and Jan Mayen
  'tk', // Tokelau
  'tc', // Turks and Caicos Islands
  'va', // Holy See
  'vg', // Virgin Islands (British)
  'vi', // Virgin Islands (U.S.)
  'wf', // Wallis and Futuna
  'um', // UNITED STATES MINOR OUTLYING I
];

// Frequent countries to place at the top of the picklist
const topOfTheListCountries = [
  'fr', // FRANCE
  'it', // ITALY
  'gb', // GREAT BRITAIN
  'us', // UNITED STATES OF AMERICA
  'mx', // MEXICO
  'kr', // KOREA
];

export interface EnrichedCountry {
  isoCode: string;
  field: string;
  topOfTheList: boolean;
  unavailable: boolean;
}

const generateCountriesOptions = (locale: AvailableLocales): EnrichedCountry[] => {
  let countries: InputCountry[] = [];
  switch (locale) {
    case 'en':
      countries = englishCountries;
      break;
    case 'fr':
      countries = frenchCountries;
      break;
    default:
      countries = englishCountries;
      break;
  }

  const enrichedCountries: EnrichedCountry[] = [];
  countries.forEach(country => {
    const isoCodeValue: string = country.alpha2;
    if (!excludedCountries.some(excluded => excluded === isoCodeValue)) {
      enrichedCountries.push({
        isoCode: isoCodeValue.toUpperCase(),
        field: country.name,
        topOfTheList: topOfTheListCountries.some(topCountry => topCountry === isoCodeValue),
        unavailable: false,
      });
    }
  });
  return enrichedCountries;
};

// 2) Ordering the countries in alphabetic order and adding a separator between frequent and non-frequent countries

const orderByCountryName = (array: EnrichedCountry[]): EnrichedCountry[] => {
  array.sort(function (a, b) {
    if (a.field < b.field) {
      return -1;
    }
    if (a.field > b.field) {
      return 1;
    }
    return 0;
  });

  return array;
};

const orderCountriesPicklist = (countryList: EnrichedCountry[]): SelectChoice<APIValue>[] => {
  // Creating top of the list array and remaining countries array
  const topOfTheList: EnrichedCountry[] = [];
  const remainingCountries: EnrichedCountry[] = [];

  countryList.forEach(field => {
    if (field.topOfTheList) {
      topOfTheList.push(field);
    } else {
      remainingCountries.push(field);
    }
  });

  // Ordering both arrays alphabetically
  orderByCountryName(topOfTheList);
  orderByCountryName(remainingCountries);

  // Adding the default 'N/A' option at the end of the top country list
  // Has to be associated with a country handled by Salesforce
  topOfTheList.push({
    isoCode: 'N_A',
    field: 'N/A',
    topOfTheList: true,
    unavailable: false,
  });

  // Then adding a visual separator
  const separator: EnrichedCountry = {
    isoCode: '',
    field: '---------------',
    topOfTheList: false,
    unavailable: true,
  };

  topOfTheList.push(separator);

  // Concatenating both lists
  const tempList = topOfTheList.concat(remainingCountries);

  // Adding an id to each country object
  const orderedList: SelectChoice<APIValue>[] = [];
  tempList.forEach((country, index) => {
    orderedList.push({
      ...country,
      id: index + 1,
    });
  });
  return orderedList;
};

export const generateOrderedLocCountryList = (locale: AvailableLocales): SelectChoice<APIValue>[] => {
  const enrichedCountryList: EnrichedCountry[] = generateCountriesOptions(locale);
  return orderCountriesPicklist(enrichedCountryList);
};
