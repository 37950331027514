import { ContactFormQuery } from '../../graphql-types';
import { ContactFormTemplateProps } from '../templates/contact-form.template';
import { AvailableLocales } from '../locale';

import { mapFragmentToCtaProps } from './cta.mapper';
import { mapFragmentToAlignedMarkdownProps } from './markdown.mapper';
import { safeApply } from './utils';

export const mapFragmentToContactFormProps = (
  query: NonNullable<ContactFormQuery['cms']['contactForm']>,
  locale: AvailableLocales
): ContactFormTemplateProps => {
  return {
    title: query?.formTitle ?? '',
    description: safeApply(value => mapFragmentToAlignedMarkdownProps(value), query?.formDescription),
    legalNotice: safeApply(value => mapFragmentToAlignedMarkdownProps(value), query?.legalNotice),
    openFormCTA: safeApply(value => mapFragmentToCtaProps(value), query?.openFormCTA),
    successTitle: query?.successFormTitle ?? '',
    successText: safeApply(text => mapFragmentToAlignedMarkdownProps(text), query?.successText),
  };
};
