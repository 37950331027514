import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from '../../../theme';

export const ContactFormWrapper = styled.div`
  height: 100%;
  width: 40%;
  margin: 0 auto;
  padding-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoint.largeMobile}) {
    width: 70%;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Header = styled.h1`
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
`;

export const DescriptionWrapper = styled.div`
  margin-bottom: 20px;
`;

export const disabled = css`
  pointer-events: none;
  background-color: ${theme.color.palette['base-gray-3']};
`;

export const TextAreaWrapper = styled.div`
  width: 100%;
  margin-top: 30px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AcceptedFormatsWrapper = styled.p`
  position: relative;
  top: 30px;
  font-size: small;
`;

export const LoaderWrapper = styled.div`
  margin: 20px 0;
`;

export const GlobalErrorWrapper = styled.span`
  color: ${theme.color.text.error};
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: normal;
`;

export const InputErrorWrapper = styled.span`
  color: ${theme.color.text.error};
  /* position: relative;
  top: 10px; */
  line-height: 1;
  text-align: right;
  width: 100%;
`;

export const TextAreaErrorWrapper = styled.span`
  color: ${theme.color.text.error};
  position: relative;
  top: -10px;
`;

export const RecaptchaErrorWrapper = styled.span`
  color: ${theme.color.text.error};
  position: relative;
  top: 10px;
`;

export const FileErrorWrapper = styled.span`
  color: ${theme.color.text.error};
  position: relative;
  top: -10px;
  width: 100%;
  margin-bottom: 10px;
  text-align: center;
`;

export const SubmitErrorsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${theme.color.text.error};
  margin: 10px 0;
  line-height: normal;
`;

export const SubmitErrorsList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  flex-wrap: wrap;
  list-style-type: none;
`;

export const ThankYouWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
