import React from 'react';

import { ContactForm, ContactFormProps } from '../components/organisms/contact-form';
import { AlignedMarkdownProps } from '../components/atoms/aligned-markdown';
import { CtaProps } from '../components/molecules/cta';

export type ContactFormTemplateProps = {
  title: string;
  description?: AlignedMarkdownProps;
  legalNotice?: AlignedMarkdownProps;
  openFormCTA?: CtaProps;
  successTitle?: string;
  successText?: AlignedMarkdownProps;
};

export const ContactFormTemplate: React.FC<ContactFormTemplateProps> = props => {
  const contactFormProps: ContactFormProps = {
    title: props.title,
    description: props.description,
    legalNotice: props.legalNotice,
    openFormCTA: props.openFormCTA,
    successTitle: props.successTitle,
    successText: props.successText,
  };

  return <ContactForm {...contactFormProps} />;
};
