import React, { useState, useRef } from 'react';
import { CTA } from '../molecules/cta';
import styled from '@emotion/styled';

export type UploadFileButtonProps = {
  title: string;
  text: string;
  handleAddAttachment: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  handleRemoveAttachment: () => void;
};

const Wrapper = styled.div`
  padding: 30px 0px;
  /* margin-bottom: 20px; */
`;

const Label = styled.label`
  min-width: 100%;
`;

const Input = styled.input`
  padding: 30px 0px;
  display: none;
`;

const fileName = (str: string) => {
  const name = str.split('\\').splice(-1)[0];
  return name.length > 30 ? name.substring(0, 27) + '...' : name;
};

export const UploadFileButton: React.FC<UploadFileButtonProps> = props => {
  const [inputValue, setInputValue] = useState('');
  const ref = useRef<HTMLInputElement>(null);

  return (
    <Wrapper>
      <Label htmlFor="input-file">
        <CTA
          text={inputValue ? fileName(inputValue) : props.text}
          title={props.title}
          icon={inputValue ? 'close' : 'up-arrow'}
          onClick={() => {
            ref.current?.click();
          }}
          onIconClick={evt => {
            if (!inputValue) return;
            setInputValue('');
            evt.stopPropagation();
            evt.preventDefault();
            props.handleRemoveAttachment();
          }}
          type="button"
        />
      </Label>

      <Input
        ref={ref}
        id="input-file"
        name="input-file"
        type="file"
        accept="image/*,.pdf"
        value={inputValue}
        onChange={evt => {
          setInputValue(evt.target.value);
          props.handleAddAttachment(evt);
        }}
      />
    </Wrapper>
  );
};
