import React from 'react';
import styled from '@emotion/styled';

export type CheckboxProps = {
  text: string;
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
`;

const CheckboxText = styled.div`
  margin-left: 10px;
`;

export const Checkbox: React.FC<CheckboxProps> = props => {
  return (
    <Wrapper>
      <input type="checkbox" checked={props.checked} onChange={props.onChange} />
      <CheckboxText>{props.text}</CheckboxText>
    </Wrapper>
  );
};
