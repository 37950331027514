import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';

import { AlignedMarkdown, AlignedMarkdownProps } from '../../atoms/aligned-markdown';
import { CTA } from '../../../components/molecules/cta';

import { ThankYouWrapper, Header } from './styled-components';

export type ContactFormStepThreeProps = {
  successTitle?: string;
  successText?: AlignedMarkdownProps;
};

export const ContactFormStepThree: React.FC<ContactFormStepThreeProps> = props => {
  const intl = useIntl();

  const translations = {
    backHomeLabel: intl.formatMessage({ id: 'contact.backHome' }),
  };

  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

  return (
    <ThankYouWrapper>
      <Header>{props.successTitle}</Header>
      <AlignedMarkdown {...props.successText} />
      <CTA text={translations.backHomeLabel} link={'/'} />
    </ThankYouWrapper>
  );
};
