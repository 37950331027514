import React from 'react';
import { graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-react-intl';
import { ContactFormQuery } from '../../graphql-types';
import SEO from '../components/seo';
import { ContactFormTemplate } from '../templates/contact-form.template';
import { mapFragmentToContactFormProps } from '../data-mappers/contact-form.mapper';
import { parseLocale } from '../locale';
import { useLocalizedSlugs } from '../hooks/useLocalizedSlugs';

export const contactFormQuery = graphql`
  query ContactForm($language: String) {
    cms {
      contactForm(locale: $language) {
        formTitle
        formDescription {
          ...AlignedMarkdown
        }
        legalNotice {
          ...AlignedMarkdown
        }
        successFormTitle
        successText {
          ...AlignedMarkdown
        }
        openFormCTA {
          ...Link
        }
      }
    }
  }
`;

const ContactForm: React.FC<{ data: ContactFormQuery }> = ({ data }) => {
  const intl = useIntl();
  const locale = parseLocale(intl.locale);

  useLocalizedSlugs({
    slugs: [
      { locale: 'en', slug: 'contact-form' },
      { locale: 'fr', slug: 'formulaire-de-contact' },
    ],
  });

  if (!data?.cms?.contactForm) return null;

  const props = mapFragmentToContactFormProps(data?.cms?.contactForm, locale);

  return (
    <>
      <SEO lang={locale} title={props.title} />
      <ContactFormTemplate {...props} />
    </>
  );
};

export default ContactForm;
